<template>
  <b-overlay :show="loading">
    <div class="mt-5">
      <list-table
        :show-search="false"
        :columns="tableColumns"
        :records="myCandidates"
        :search-filter.sync="search"
        :total-records="totalRecords"
        :show-filter-by-default="false"
      >
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                :to="{ name: 'single-candidate', params: { id: data.value._id } }"
                :src="getValueFromSource(data, 'value.avatar')"
                :text="sentenceCase(avatarText(`${data.value.name}`))"
                :variant="`light-primary`"
              />
            </template>
            <b-link
              :to="{ name: 'single-candidate', params: { id: data.value._id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ sentenceCase(getValueFromSource(data, 'value.name')) }}
            </b-link>
            <span class="text-muted">{{ data.value.region }},</span><br />
            <span class="text-muted">{{ data.value.country }}</span>
          </b-media>
        </template>

      </list-table>
    </div>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ListTable from "@core/components/ListTable/ListTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import HireCandidateModal from "@/@core/components/shared/provider/HireCandidateModal.vue";

import { debounce, get } from "lodash";
import { formatDate } from "@core/utils/utils";
import { BOverlay, BLink, BButton, BModal, BRow, BCol, BTooltip, BAvatar, BMedia, BBadge, BDropdown, BDropdownItem, } from "bootstrap-vue";

const watchHandler = {
  handler(){
    this.debounceFetch()
  },
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler(){
    this.currentPage = 1;
    this.debounceFetch()
  },
  immediate: false
}

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BModal,
    BMedia,
    BBadge,
    BButton,
    vSelect,
    BAvatar,
    BTooltip,
    BOverlay,
    ListTable,
    BDropdown,
    BDropdownItem,
    HireCandidateModal
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      myCandidates: [],
      toggleHireModal: false,
      hiringCandidate: null,

      search: "",
      debounceFetch: null,

      tableColumns: [
        { key: 'user', label: 'Candidate', sortable: false },
        { key: 'employment_type', label: 'Type', sortable: false },
        { key: 'job_role', sortable: false },
        { key: 'job_category', sortable: false },
        { key: 'date_employed', formatter: val => `${formatDate(val, 'dd-MM-yy')}`, sortable: false },
        { key: 'created', sortable: false, formatter: val => `${formatDate(val, 'dd-MM-yy h:m:s a')}`, label: 'Date', class: 'width-200' },
      ]
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    currentPage: watchHandler,
    statusFilter: watchHandler,
    search: watchHandlerRequiresPageReset,
    perPage: watchHandlerRequiresPageReset,
  },
  created() {
    this.debounceFetch = debounce(this.fetch, 500);

    const {
      search,
      page = 1,
      limit = this.perPage
    } = this.$route.query;

    this.search = search;
    this.perPage = +limit;
    this.currentPage = +page;
  },
  methods: {
    async fetch() {
      try {
        this.loading = true;

        const query = {
          limit: this.perPage,
          search: this.search,
          page: this.currentPage,
        }
        await this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().providerService.myCandidates(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords;
        this.perPage = pagination.limit;
        this.myCandidates = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>

</style>
